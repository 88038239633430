<template>
  <figure
    class="relative flex gap-3 font-normal md:gap-4"
    data-el="ui-cart-item-details"
  >
    <slot v-bind="{ link: props.link }">
      <div class="flex w-24 items-center justify-center gap-2">
        <UiProductImage
          v-if="props.image?.src"
          class="flex items-end gap-2 md:flex-col md:items-end md:justify-end"
          :image="props.image"
          :link="props.link"
          :enable-show-item-image-on-click="props.enableShowItemImageOnClick"
          :style="{ minHeight: `${ProductImageDimensions.height}px` }"
          @click:show-image="emit('click:show-image', $event)"
        />
      </div>
    </slot>
    <div class="w-full">
      <div class="flex items-center justify-between gap-2">
        <slot
          name="brandImage"
          v-bind="{
            brand: props.brand,
            sku: props.sku,
            barcode: props.barcode,
            userCode: props.userCode,
          }"
        >
          <UiImage
            v-if="props.brand?.src"
            v-bind="props.brand"
            loading="lazy"
            fetchpriority="low"
            :style="{ maxWidth: '80px' }"
          />
        </slot>

        <slot name="specialOfferTag">
          <UiTag
            v-if="displaySpecialOfferTag"
            :tag="labels.specialOffer"
            variant="specialOffer"
            small
          />
        </slot>
      </div>
      <component
        :is="isNuxtLink"
        :to="props.link"
        class="font-medium text-primary"
        :style="{ fontSize: 'inherit' }"
      >
        <slot
          name="name"
          v-bind="{ name }"
        >
          {{ props.name }}
        </slot>
      </component>
      <slot name="hr">
        <hr class="my-2.5 border-blue-450">
      </slot>

      <div class="flex items-center justify-between gap-2">
        <UiProductDetailsCodes
          v-if="props.sku || props.userCode || props.barcode"
          :sku="props.sku"
          :user-code="props.userCode"
          :barcode="props.barcode"
          :inline="props.displayProductCodesInline"
        />

        <slot
          name="actions"
          v-bind="{
            sku: props.sku,
          }"
        />
      </div>

      <div class="flex items-end justify-between">
        <slot name="order" />
      </div>
    </div>
  </figure>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import UiProductImage from '../Magento/Product/UiProductImage/UiProductImage.vue'
import { ProductImageDimensions } from '../Magento/Product/UiProductImage/UiProductImage.types'
import UiImage from '../UiImage/UiImage.vue'
import type { ImageInterface } from '../UiImage/UiImage.types'
import UiTag from '../UiTag/UiTag.vue'
import UiProductDetailsCodes from './UiProductDetailsCodes/UiProductDetailsCodes.vue'
import type { ProductDetailsProps } from './UiProductDetails.types'

const props = withDefaults(defineProps<ProductDetailsProps>(), {
  image: null,
  link: '',
  userCode: '',
  brand: null,
  sku: '',
  barcode: '',
  displayProductCodesInline: false,
  displaySpecialOfferTag: false,
  enableShowItemImageOnClick: true,
  labels: () => ({
    specialOffer: '',
  }),
})

const emit = defineEmits<{
  (e: 'click:show-image', value: ImageInterface): void
}>()

const isNuxtLink = computed(() =>
  props.link ? resolveComponent('nuxt-link') : 'p',
)
</script>
